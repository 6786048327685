<template>
  <div class="home">
    <div class="header">
      <VanIcon @click="black" class="arrow" name="arrow-left" />
      <p>代理赚钱</p>
      <span class="rule" @click="popup">代理说明</span>
    </div>
    <div class="banner">
      <img class="img" src="@/assets/images/banner.png"/>
    </div>
    <div class="item">
      <div class="icon">
        <img class="img" src="@/assets/images/icon1.png">
      </div>
      <div class="cont">
        <div class="h1">加盟好处</div>
        <div class="p">0投资：没有门槛，专心做推广即可</div>
        <div class="p">无脑运营：只需要一键分享转发，操作简单</div>
        <div class="p">利润高：最高返利20%，多劳多得</div>
        <div class="p">财务透明：代理流水随时查看核对，公开透明</div>
      </div>
    </div>
    <div class="item">
      <div class="icon">
        <img class="img" src="@/assets/images/icon2.png">
      </div>
      <div class="cont">
        <div class="h1">如何成为代理</div>
        <div class="p">分享二维码或链接给其他人下载注册即可成</div>
        <div class="p">为代理，推广越多返利越丰厚！</div>
      </div>
    </div>
     <div class="item">
      <div class="icon">
        <img class="img" src="@/assets/images/icon3.png">
      </div>
      <div class="cont">
        <div class="h1">如何推广</div>
        <div class="p">1.通过app内任意影片，<div class="b">【保存二维码图片】</div></div>
        <div class="p">或<div class="b">【复制链接】</div>分享给好友；</div>
        <div class="p">2.新用户下载安装app后，即可绑定推广关系</div>
      </div>
    </div>
    <div class="itemBox">
      <div class="item">
        <div class="icon">
          <img class="img" src="@/assets/images/icon4.png">
        </div>
        <div class="cont">
          <div class="h1">代理佣金</div>
          <div class="p">以下两种代理方案可任选其一，不可叠加业绩</div>
          <div class="h2">1.分享安装佣金：</div>
          <div class="p">一个安装可获得<div class="b">1元佣金</div><div class="button" @click="Application1">立即申请</div></div>
          <div class="h2">2.充值VIP佣金</div>
          <div class="p">一级用户充值VIP,代理人可获得<div class="b">最低5%最高</div></div>
          <div class="p"><div class="b">20%</div>的佣金分成<div class="button" @click="Application2">立即申请</div></div>
          <div class="p" style="padding-top:15px">代理人直接推送的用户为一级用户，一级用户</div>
          <div class="p">的每次消费，均会计入业绩,产生返利,并随</div>
          <div class="p">着业绩的提高,返利比例逐步提高。</div>
          <div class="h2" style="padding-top:15px">佣金分成计算方式 例：</div>
          <div class="p">一级用户充值金额为5000元,<div class="b">佣金分成=充值</div></div>          
          <div class="p"><div class="b">金额*5%（返佣比例）</div></div>      
        </div>
      </div>
        <div class="tab">
          <img class="img" src="@/assets/images/tab.png">
        </div>
    </div>
    
    <!-- <div class="item">
      <div class="icon">
        <img class="img" src="@/assets/images/icon5.png">
      </div>
      <div class="cont">
        <div class="h1">代理要求</div>
        <div class="p">1.代理线下当日新安装用户次日留存需达到</div>
        <div class="p"><div class="b">35%</div>，才可判断为当日新增为有效安装。</div>
        <div class="p">计算方式：为当日新安装并且在次日启动app</div>
        <div class="p">的用户数/当日新安装用户<div class="b">≥35%</div></div>
        <div class="p">2.代理账户佣金单次提款金额需达到500元</div>
        <div class="p">以上才可进行提款申请；</div>
        <div class="p">3.被邀请用户需手机登录才可判断为有效安装</div>
      </div>
    </div>
     <div class="item">
      <div class="icon">
        <img class="img" src="@/assets/images/icon6.png">
      </div>
        <div class="cont">
          <div class="h1">提款规则</div>
          <div class="p">1.每位代理每7天可进行一次提款操作，可通</div>
          <div class="p">过银行卡进行提现；</div>
          <div class="p">2.发起提款申请后7个工作日之内到账；</div>
          <div class="p">3.如发现存在虚假安装，虚假流量情况，将</div>
          <div class="p">扣除当前账户50%的佣金作为处罚；</div>
        </div>
    
     </div> -->
     <div class="contact">
       <div class="h1">
         {{contactH1}}
       </div>
       <div class="p" v-for="(item,index) in contactList" :key="index">
         {{item}}
       </div>
       <div class="contxt">
         <div class="icon">
           <img class="img" :src="thumb" />
         </div>
        {{contxt}}
       </div>
     </div>
     <div class="con">
        <i></i>
       <p>{{formatDate(timestamp*1000)}}更新</p>
     </div>
     <VanPopup class="show" closeable v-model="show1">
        <div class="h2">
          <img class="img" src="@/assets/images/title.png"/>
        </div>
        <div class="cont">
          <div class="h3">
            一、代理规则
          </div>
          <div class="p">
            1.代理线下当日新安装用户次日留存需达到35%才可判断为当日新增为有效安装（指分享安装代理）；
          </div>
          <div class="p">
            计算方式：当日新安装并且在次日启动APP的用户数/当日新安装用户≥35%
          </div>
          <div class="p">
            2.被邀请用户需手机登录才可判断为有效安装；
          </div>
           <div class="h3">
            二、提款规则
          </div>
          <div class="p">
            1.代理账户佣金单次提款金额需达到500元以上才可进行提款申请；
          </div>
          <div class="p">
            2.每位代理每7天可进行一次提款操作，可通过银行卡进行提现；
          </div>
          <div class="p">
            3.发起提款申请后3个工作日之内到账；
          </div>
           <div class="p">
            4.如发现存在虚假安装，虚假流量情况，将扣除当前账户50%的佣金作为处罚；
          </div>
        </div>
      </VanPopup>
  </div>
</template>

<script>
import {
  Empty as VanEmpty,
  Icon as VanIcon,
  List as VanList,
  Overlay as VanOverlay,
  Popup as VanPopup,
  Tab as VanTab,
  Tabs as VanTabs,
  Toast,
} from 'vant';
import http from "@/http/request"
export default {
  name: 'Home',
  components: {
    VanTabs,
    VanTab,
    VanEmpty,
    VanIcon,
    VanOverlay,
    VanList,
    VanPopup,
  },
  data() {
    return {
      contactH1:'',
      contactList:[],
      thumb:'',
      contxt:'',
      timestamp:0,
      show1:false,
      appid:'',
      pt:'',
      agentid:''
    };
  },
  created() {
    this.appid = this.GetQueryString("appid")
    this.pt = this.GetQueryString("pt")
    this.agentid = this.GetQueryString("agentid")
    let user = {
      appid:this.appid
    }
    let _this = this
    Toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });

    http.all([
      http.post("/v1/user/getH5AgentConfig", user),
    ]).then(http.spread(function(contact,) {
      Toast.clear();

      if (contact.code == 0) {
        _this.contactH1 = contact.info.share_agent_config.content.split('\n')[0]
        _this.contactList = contact.info.share_agent_config.content.split('\n').slice(1)
        _this.thumb = contact.info.share_agent_config.thumb
        _this.contxt = contact.info.share_agent_config.content_ext
        _this.timestamp = contact.timestamp

      } else {
        Toast(contact.msg)
      }
    })).catch((err) => {
      Toast.clear();
      Toast("获取数据失败！")
      console.log('FAIL', err)
    });
  },
  computed: {

  },
  methods: {
    GetQueryString(name){
      let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if(r!=null)return  unescape(r[2]); return null;
    },
     black(){
      AndroidClient.backToHome()
    },
    formatDate(value){
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return y + '-' + MM + '-' + d
    },
    popup(){
      this.show1= true
    },
    Application1(){
      if(window.location.host == 'make.782oxxrh.com'){
        window.open(`https://uat-cpafont.4e5b7a.com/admin.php/example/login/login?agentid=${this.agentid}`,'_self')
      }else{
        window.open(`https://cpa-api.39adcd5.com/admin.php/example/login/login?agentid=${this.agentid}`,'_self')
      }
    },
    Application2(){
      if(window.location.host == 'make.782oxxrh.com'){
        window.open(`http://front.b593260.com/?appid=${this.appid}&pt=${this.pt}/#/index/`,'_self')
      }else{
        window.open(`http://front.d303065.com/?appid=${this.appid}&pt=${this.pt}/`,'_self')
      }
      
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss">
body{
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  background: #141028;
}
.home {
  .img{
      width: 100%;
      height: 100%;
      display: block;
  }
  .header{
    padding: 10px 0 10px 0;
    display: flex;
    background: url(../assets/images/topbg.png) no-repeat;
    background-size:100% 100% ;
    line-height: 50px;
    position: relative;
    justify-content: center;
    p{
      font-size: 18px;
      color: #FFFFFF;
      display: block;
      // padding-left: 80px;
      // text-align: center;
    }
    .arrow{
      color: #fff;
      line-height: 50px;
      padding-left: 20px;
      margin-top: 1px;
      position: absolute;
      left: 10px;
    }
    .rule{
      width: 60px;
      height: 23px;
      background: linear-gradient(131deg, #A536FF 0%, #7123FB 100%);
      border-radius: 11px;
      display: block;
      font-size: 12px;
      color: #FFFFFF;
      text-align: center;
      line-height: 23px;
      position: absolute;
      right: 16px;
      top: 22px;
    }
  }
  .banner{
    width: 93%;
    margin: 8px auto;
    border: 5px;
  }
  .item{
    background: #221C3C;
    border-radius: 6px;
    display: flex;
    width: 93%;
    margin: 8px auto;
    border: 5px;
    flex-wrap:wrap;
    .icon{
      width: 44px;
      height: 44px;
      padding: 18px 10px 0px 10px ;
    }
    .cont{
        padding: 18px 0px 22px 0;
       .h1{
        font-size: 14px;
        color: #DBDEEA;
        padding-bottom: 5px;
      }
      .h2{
        font-size: 12px;
        color: #DBDEEA;
      }
      .p{
        font-size: 12px;
        color: #8C8CB1;
        padding-bottom: 2px;
        display: flex;
        flex-wrap:wrap;
      }
      .b{
        color: #FD4768;
      }
      .button{
        background: linear-gradient(121deg, #F6E6D1 0%, #EBD6AB 100%);
        border-radius: 6px;
        font-size: 12px;
        color: #AA0000;
        width: 60px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        margin-left: 10px;
      }
    }
    .post{
      width: 100%;
      padding-left: 18px;
      padding-bottom: 30px;
    }
  }
  .contact{
    padding-top: 10px;
    padding-left: 12px;
    .h1{
      font-size: 14px;
      color: #F8F8F8;
      padding-bottom: 5px;
    }
    .p{
      font-size: 12px;
      color: #8C8CB1;
      padding-bottom: 5px;
    }
    .contxt{
      padding-top: 20px;
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      color: #F8F8F8;
      .icon{
        width: 30px;
        height: 30px;
      }
    }
  }
   .con{
     position:relative;
     width: 80%;
     height:1.875rem;
     line-height: 1.875rem;
     margin:2.47rem auto;
     text-align: center;
     i{
       display:block;
       height:1px;
       background:#8C8CB1;
       position:absolute;
       top:0.9rem;
       width:100%;
      }
     p{
        display:inline-block;
        font-size: 12px;
        color: #8C8CB1;
        background: #141028;
        padding:0 1.875rem;
        text-align: center;
        margin:0 auto;
        position:relative;
        z-index:2;
      }
    }
    .itemBox{
      background: #221C3C;
      width: 93%;
      margin: 0.5rem auto;
      border: 0.3125rem;
      border-radius: 0.375rem;
      .item{
        width: 100%;
        background: none;
        margin:0;
      }
      .tab{
        width: 85%;
        margin: 0 auto;
        padding: 20px 0;
      }
    }
    .show{
      width: 75%;
      height: 430px;
      background: url(../assets/images/popbg.png) no-repeat 0 0/100% 100%;
      box-shadow: inset 0 1px 5px 0 #A190FC;
      border-radius: 10.56px;
      margin: 0 auto;
      overflow:visible ;
      .van-popup__close-icon{
        width: 20px;
        height: 20px;
        border: 1px solid #dfdfdf;
        border-radius: 50%;
        font-size: 10px;
        color: #fff;
        text-align: center;
        line-height: 20px;
        top: -50px;
        right: 0px;
        z-index: 9999;
        position: fixed;
      }
      .h2{
        width: 50%;
        margin: 25px auto 10px auto;
      }
      .cont{
        padding: 0px 25px;
        .h3{
          font-size: 12px;
          color: #FFFFFF;
          padding-top: 10px;
        }
        .p{
          font-size: 12px;
          color: #D0D8F4;
          padding-top: 5px;
        }
      }
  }
}
</style>
